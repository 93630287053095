import React from 'react'

import styled from 'styled-components'
import { H2, P } from '../utils/typography'
import Button from './Button'

import control from '../assets/images/control.svg'

const Control = () => {
    return (
      <StyledControl>
        <div className="container">
          <H2>
            You’re In <span>Control</span>
          </H2>
          <P>
            You have full visibility and control on what recruitment challenge you
            like to pick and participate in. You can do this in your own time any
            time.
          </P>
  
          <Button
            marginTop="2rem"
            text="Get Started"
            link="https://forms.gle/ETBLKt9cxNJDQw8T6"
          />
        </div>
  
        <img src={control} alt="control" className="control" />
      </StyledControl>
    )
  }
  
  export default Control
  
  const StyledControl = styled.section`
    width: 100%;
    max-width: 100vw;
    min-height: 85vh;
    padding: 0 9%;
    display: flex;
    align-items: center;
    gap: 7rem;
  
    .container {
      width: 100%;
      max-width: 680px;
    }
  
    .control {
      width: 20rem;
      height: auto;
    }
  
    @media (max-width: 768px) {
      flex-direction: column;
      gap: 3rem;
      min-height: 70vh;
  
      .container {
        order: 2;
      }
  
      .control {
        order: 1;
        width: 12rem;
      }
    }
`