import React from 'react'

import styled from 'styled-components'
import { P } from '../utils/typography'

const Card = ({ num, content, className }) => {
  return (
    <StyledCard className={className}>
      <h1 className="num">{num}</h1>
      <P>{content}</P>
    </StyledCard>
  )
}

export default Card

const StyledCard = styled.div`
  width: 100%;
  max-width: 435px;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.black};
  box-sizing: border-box;
  border-radius: 2px;
  position: relative;
  padding: 3rem 2.5rem;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border: 1px solid ${({ theme }) => theme.colors.black};
    border-radius: 2px;
    bottom: -12px;
    right: -12px;
    z-index: -1;
  }

  .num {
    font-family: 'Archivo Black', sans-serif;
    font-size: 7rem;
    font-weight: ${({ theme }) => theme.fontWeights.regular};
    color: ${({ theme }) => theme.colors.grey};
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: ${({ theme }) => theme.colors.black};
  }
`