import React, { useEffect } from 'react'

import styled from 'styled-components'
import { H2 } from '../utils/typography'
import Card from './Card'

import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const processData = [
  {
    num: 1,
    content: `You will be invited to accept the recruitment challenge`,
  },
  {
    num: 2,
    content: `When accepted, You will be asked to share matching CV’s`,
  },
  {
    num: 3,
    content: `When Matched and Shortlisted, You & Candidate will be invited for an interview. 
    Upon Successful hire, You will get Paid.`,
  },
]

const Process = () => {
  useEffect(() => {
    gsap.from('.card', {
      scrollTrigger: {
        trigger: '.process',
        start: 'top 100',
        markers: false,
        toggleActions: 'restart pause reverse pause',
      },
      y: 100,
      stagger: 0.2,
      duration: 1,
    })
  }, [])
  return (
    <StyledProcess className="process">
      <H2 marginBottom="3rem">
        An Easy <br />
        <span>Three </span>Step Process
      </H2>

      <Grid>
        {processData.map(({ num, content }) => (
          <Card className="card" key={num} num={num} content={content} />
        ))}
      </Grid>
    </StyledProcess>
  )
}

export default Process

const StyledProcess = styled.section`
  width: 100%;
  max-width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 0 9%;

  @media (max-width: 768px) {
    padding: 0 6%;
    margin-top: 2rem;
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;

  @media (max-width: 768px) {
    grid-template-columns: 100%;
  }
`