import React, { useEffect } from 'react'

import styled from 'styled-components'

import logo from '../assets/images/logoIcon.svg'
import { H2, P } from '../utils/typography'
import Button from './Button'

import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const HomeFooter = () => {
  useEffect(() => {
    gsap.to('.logo', {
      scrollTrigger: {
        trigger: '.footer',
        start: 'top 100',
        markers: false,
        toggleActions: 'restart pause reverse pause',
      },
      rotation: 360,
      duration: 1,
    })
  }, [])

  return (
    <StyledHomeFooter className="footer">
      <img src={logo} alt="hopla logo" className="logo" />
      <H2 textAlign="center" maxWidth="800px">
        Let’s Get Things <span>Done</span>
      </H2>
      <P textAlign="center" maxWidth="650px">
        {`
                Don’t wait any further as this initial invitation expires on <Date>. 
                Send us your details and together, let’s start getting things DONE!
                `}
      </P>

      <Button
        marginTop="3rem"
        text="Get Started"
        link="https://forms.gle/ETBLKt9cxNJDQw8T6"
      />
    </StyledHomeFooter>
  )
}

export default HomeFooter

const StyledHomeFooter = styled.div`
  width: 100%;
  max-width: 100vw;
  min-height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .logo {
    width: 8rem;
    height: auto;
    margin-bottom: 2rem;
  }

  @media (max-width: 768px) {
    padding: 0 6%;

    .logo {
      width: 6rem;
    }
  }
`