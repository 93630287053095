import React from 'react'
import Control from '../components/Control'
import Hero from '../components/Hero'
import HomeAbout from '../components/HomeAbout'
import HomeFooter from '../components/HomeFooter'
import Process from '../components/Process'

const Home = () => {
  return (
    <>
        <Hero />
        <HomeAbout />
        <Control />
        <Process />
        <HomeFooter />
    </>
  )
}

export default Home