import React, { useState } from 'react'

import styled from 'styled-components'

import logo from '../assets/images/logo.svg'
import { Link, NavLink } from 'react-router-dom'

import menu from '../assets/images/menu.svg'

const Header = () => {
  const [showMenu, setShowMenu] = useState(false)

  return (
    <StyledHeader>
      <Link to="/">
        <img className="logo" src={logo} alt="hopla logo" />
      </Link>

      <div className={showMenu ? 'menu active' : 'menu'}>
        <NavLink
          exact
          onClick={() => setShowMenu(!showMenu)}
          className="link"
          activeClassName="active"
          to="/"
        >
          Home
        </NavLink>
        <NavLink
          exact
          onClick={() => setShowMenu(!showMenu)}
          className="link"
          activeClassName="active"
          to="/about"
        >
          About
        </NavLink>
        <a
          href="https://forms.gle/ETBLKt9cxNJDQw8T6"
          className="link active"
          target="_blank"
          rel="noopener noreferrer"
        >
          Join Program
        </a>
      </div>

      <img
        src={menu}
        alt="menu icon"
        className="menuIcon"
        onClick={() => setShowMenu(!showMenu)}
      />

      {showMenu && (
        <div className="overlay" onClick={() => setShowMenu(!showMenu)} />
      )}
    </StyledHeader>
  )
}

export default Header

const StyledHeader = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.125rem;

  .logo {
    width: 12rem;
    height: auto;
  }

  .menuIcon {
    width: 2rem;
    height: auto;
    cursor: pointer;
    display: none;

    @media (max-width: 768px) {
      display: block;
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 3;

    @media (min-width: 768px) {
      display: none;
    }
  }

  .link {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.black};
    font-size: ${({ theme }) => theme.fontSizes.heroLink};
    font-weight: ${({ theme }) => theme.fontWeights.regular};
    line-height: ${({ theme }) => theme.lineHeights.paragraph};
    margin-left: 3rem;

    &::first-child {
      margin-left: 0;
    }

    &.active,
    &:hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  .menu {
    @media (max-width: 768px) {
      width: 70vw;
      height: 100%;
      background: ${({ theme }) => theme.colors.white};
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: fixed;
      top: 0;
      right: -100%;
      z-index: 10;
      transition: all 0.3s ease-in-out;

      &.active {
        right: 0;
      }

      a {
        margin-bottom: 3rem;
      }
    }
  }
`
