import styled from "styled-components";

export const H1 = styled.h1`
    font-size: ${({fontSize, theme}) => fontSize ? fontSize : theme.fontSizes.largeHeading};
    font-weight: ${({fontWeight, theme}) => fontWeight ? fontWeight : theme.fontWeights.bold};
    line-height: ${({lineHeight, theme}) => lineHeight ? lineHeight : theme.lineHeights.heading};
    margin-bottom: ${({marginBottom, theme}) => marginBottom ? marginBottom : theme.spacing.s};
    color: ${({color, theme}) => color ? color : theme.colors.black};
    max-width: ${({maxWidth}) => maxWidth ? maxWidth : "100%"};

    span {
        color: ${({color, theme}) => color ? color : theme.colors.primary};
        text-transform: uppercase;
    }

    @media (max-width: 768px) {
        font-size: 3rem;
    }
`

export const H2 = styled.h2`
    font-size: ${({fontSize, theme}) => fontSize ? fontSize : theme.fontSizes.heading};
    font-weight: ${({fontWeight, theme}) => fontWeight ? fontWeight : theme.fontWeights.bold};
    line-height: ${({lineHeight, theme}) => lineHeight ? lineHeight : theme.lineHeights.heading};
    margin-bottom: ${({marginBottom, theme}) => marginBottom ? marginBottom : theme.spacing.s};
    color: ${({color, theme}) => color ? color : theme.colors.black};
    max-width: ${({maxWidth}) => maxWidth ? maxWidth : "100%"};
    text-align: ${(textAlign) => textAlign || 'left'};

    span {
        color: ${({color, theme}) => color ? color : theme.colors.primary};
    }

    @media (max-width: 768px) {
        font-size: 2.5rem;
    }
`

export const H4 = styled.h4`
    font-size: ${({fontSize, theme}) => fontSize ? fontSize : theme.fontSizes.smallHeading};
    font-weight: ${({fontWeight, theme}) => fontWeight ? fontWeight : theme.fontWeights.bold};
    line-height: ${({lineHeight, theme}) => lineHeight ? lineHeight : theme.lineHeights.heading};
    margin-bottom: ${({marginBottom, theme}) => marginBottom ? marginBottom : theme.spacing.s};
    text-align: ${({textAlign}) => textAlign ? textAlign : "left"};
    color: ${({color, theme}) => color ? color : theme.colors.black};
    max-width: ${({maxWidth}) => maxWidth ? maxWidth : "100%"};
`

export const P = styled.p`
    font-size: ${({fontSize, theme}) => fontSize ? fontSize : theme.fontSizes.paragraph};
    font-weight: ${({fontWeight, theme}) => fontWeight ? fontWeight : theme.fontWeights.regular};
    line-height: ${({lineHeight, theme}) => lineHeight ? lineHeight : theme.lineHeights.paragraph};
    margin-bottom: ${({marginBottom, theme}) => marginBottom ? marginBottom : 0};
    color: ${({color, theme}) => color ? color : theme.colors.darkGrey};
    max-width: ${({maxWidth}) => maxWidth ? maxWidth : "100%"};
    text-align: ${({textAlign}) => textAlign ? textAlign : "left"};

    @media (max-width: 768px) {
        font-size: 1rem;
        text-align: ${({ textAlignMobile }) => textAlignMobile || 'left'}
    }
`