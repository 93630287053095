import React from 'react'

import styled from 'styled-components'

const Button = ({ link, text, marginTop }) => {
  return (
    <StyledButton
      marginTop={marginTop}
      target="_blank"
      rel="noopener noreferrer"
      href={link}
    >
      {text}
    </StyledButton>
  )
}

export default Button

const StyledButton = styled.a`
  color: ${({ theme }) => theme.colors.black};
  padding: 1.125rem 2.5rem;
  border: 1px solid ${({ theme }) => theme.colors.black};
  border-radius: 100px;
  font-size: ${({ theme }) => theme.fontSizes.heroLink};
  max-width: 165px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin-top: ${({ marginTop }) => marginTop || '0'};
  transition: 0.3s ease-in-out all;

  &:hover {
    background: ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.white};
  }
`
