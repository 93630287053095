import React, { useEffect } from 'react'

import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import styled from 'styled-components'
import { H1, H4, P } from '../utils/typography'
import Button from './Button'

import aboutPatternLeft from '../assets/images/aboutPatternLeft.png'
import aboutPatternRight from '../assets/images/aboutPatternRight.png'

gsap.registerPlugin(ScrollTrigger)

const HomeAbout = () => {
  useEffect(() => {
    gsap.from('.left', {
      scrollTrigger: {
        trigger: '.about-img',
        start: 'top 0',
        markers: false,
        toggleActions: 'restart pause reverse pause',
      },
      left: '-7rem',
      duration: 1,
    })

    gsap.from('.right', {
      scrollTrigger: {
        trigger: '.about-img',
        start: 'top 0',
        markers: false,
        toggleActions: 'restart pause reverse pause',
      },
      right: '-7rem',
      duration: 1,
    })
  }, [])

  return (
    <StyledHomeAbout className="about-image">
      <img
        src={aboutPatternLeft}
        className="pattern left"
        alt="about pattern"
      />
      <div className="container">
        <H4 textAlign="center">Earn up to</H4>
        <H1 textAlign="center">
          <span>15K </span>PESOS
        </H1>
        <P textAlign="center" maxWidth="570px">
          Unlimited earning potential, no limit to the challenges you can
          participate in. Easy payout process, model and actual payment. A
          proved and trustworthy engagement with a 100% guarantee payment as per
          the agreed terms and conditions.
        </P>
        <Button
          marginTop="3rem"
          text="Get Started"
          link="https://forms.gle/ETBLKt9cxNJDQw8T6"
        />
      </div>
      <img
        src={aboutPatternRight}
        className="pattern right"
        alt="about pattern"
      />
    </StyledHomeAbout>
  )
}

export default HomeAbout

const StyledHomeAbout = styled.section`
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  min-height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    padding: 0 6%;
  }

  .pattern {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 21rem;
    height: auto;

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }

    @media (max-width: 768px) {
      display: none;
    }
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 722px;
  }
`