import React, { useEffect } from 'react'

import styled from 'styled-components'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { H2, P } from '../utils/typography'
import Button from './Button'

import heroImage from '../assets/images/heroImage.svg'

gsap.registerPlugin(ScrollTrigger)

const Hero = () => {
  useEffect(() => {
    gsap.to('.heroImage', {
      scrollTrigger: {
        trigger: '.heroImage',
        start: 'top 0',
        markers: false,
        toggleActions: 'restart pause reverse pause',
      },
      scale: 1.25,
      duration: 1,
    })
  }, [])

  return (
    <StyledHero className="hero">
      <div className="container">
        <H2 maxWidth="926px">
          <span>HOPLA </span>Recruitment Challenger Program
        </H2>
        <P maxWidth="700px" textAlignMobile="center">
          Our Challenger Program matches global job opportunities with local
          recruitment, HR, and other people & culture practitioners,
          freelancers, professionals allowing for additional money making
          possibilities.
        </P>
        <Button
          marginTop="2rem"
          text="Get Started"
          link="https://forms.gle/ETBLKt9cxNJDQw8T6"
        />
      </div>

      <img src={heroImage} alt="hero copy" className="heroImage" />
    </StyledHero>
  )
}

export default Hero

const StyledHero = styled.section`
  width: 100%;
  max-width: 100vw;
  min-height: calc(100vh - 6rem);
  padding: 0 9%;
  display: flex;
  align-items: center;
  gap: 3rem;

  .container {
    width: 100%;
    max-width: 850px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .heroImage {
    width: 20rem;
    height: auto;
  }

  @media (max-width: 768px) {
    flex-direction: column;

    .container {
      order: 2;
      align-items: center;
    }

    .heroImage {
      width: 12rem;
      order: 1;
    }
  }
`