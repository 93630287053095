const theme = {
    colors: {
        primary: "#FF5880",
        black: "#1A1A1A",
        white: "#FFF",
        darkGrey: "#878787",
        grey: "#F6E5E9"
    },
    fontSizes: {
        heroLink: ".875rem",
        buttonText: "1.125rem",
        paragraph: "1.25rem",
        smallHeading: "3.5rem",
        heading: "4.5rem",
        largeHeading: "7rem",
    },
    fontWeights: {
        regular: 400,
        bold: 700,
    },
    lineHeights: {
        paragraph: 1.5,
        heading: 1,
    },
    spacing: {
        xs: "0.5rem",
        s: "1rem",
        m: "2rem",
        l: "2.5rem",
        xl: "3rem",
    }
}

export default theme