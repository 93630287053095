import React from 'react'

import styled from 'styled-components'
import { H2, P } from '../utils/typography'

const AboutContent = () => {
    return (
        <StyledAboutContent>
        <H2>
            About <span>HOPLA</span>
        </H2>
        <P marginBottom="2rem">
            We are a fast and exciting growing company enabling remote professionals
            to work with global organizations in a fully tested distributed working
            environment. Essentially we set up distributed teams that work from home
            using vetted online working applications, tools and processes to make
            work as well as interaction seamlessly.
        </P>
        <P marginBottom="2rem">
            One of our key principles is to provide more working opportunities, jobs
            to professionals living as well as working in more remote locations, or
            simply chosen to work from home on their own. This Challenger program is
            based on our own remote principles as well as philosophies of Uber,
            Airbnb, TaskRabbit i.e. Sharing & On Demand Economies. Our Challenger
            Program matches global job opportunities with local recruitment, HR, and
            other people & culture practitioners, freelancers, professionals.
        </P>
        <P marginBottom="2rem">
            In the Sharing & On Demand economy, people find themselves working as if
            they would be their own business owner, use their extra time outside
            their job, or simply allocate 100% of their time working as a tasker,
            driver etc. In the Hopla Challenger Program we adopt the same principles
            and allow you to earn money by participating in our program and provide
            us with readily available, vetted profiles & job descriptions.
        </P>
        <P marginBottom="2rem">
            You may find yourself in a recruiter job and yet your talent pipeline is
            not 100% maximized, you might be a professional that sees good talent go
            to waste, or companies can&apos;t find the right job fit or career, or
            you simply know talent that are in need for a different career journey.
            When this all sounds familiar and makes you excited to earn some extra
            money, you should join our Challenger program.
        </P>
        </StyledAboutContent>
    )
}

export default AboutContent

const StyledAboutContent = styled.section`
    width: 100%;
    max-width: 1000px;
    min-height: 100vh;
    padding: 5rem 9%;
`  